<template>
  <div class="full-area" :class="{'feedback': feedback}">
    <FeedbackScreen
      :data="data"
      :feedback="feedback"
      v-on:retry="retry()"
      v-on:next="emitNext()"
    ></FeedbackScreen>

    <QuestionHeader
      :data="data"
      :no-question-title="true"
    ></QuestionHeader>

    <h2
      class="optional-question-title"
      :class="{'question-subtitle-strong': data.fields.questionImage}"
      v-if="data.fields.questionSubtitle"
    >{{data.fields.questionSubtitle}}</h2>

    <content class="content">
      <div class="nps-wrapper delay-entry">
        <div
          class="nps-box"
          :class="{'selected': index === form.selected[0]}"
          v-for="index in scale"
          :key="index"
          @click="selectThis(index)"
        >{{index}}</div>
      </div>
      <div class="slider-labels" v-if="data.fields.textScale">
        <div
          class="label-item"
          v-for="text in data.fields.textScale"
          :key="text"
        >
          {{text}}
        </div>
      </div>
    </content>
    <footer>
      <SkipQuestionButton
        :location="'locate-bottom'"
        v-on:skip="selectThis('S99')"
      ></SkipQuestionButton>
    </footer>
  </div>
</template>

<script>
import chroma from 'chroma-js'

export default {
  name: 'NPS',

  props: [ 'data' ],

  components: {
    FeedbackScreen: () => import('Components/base/FeedbackScreen'),
    QuestionHeader: () => import('Components/base/QuestionHeader'),
    SkipQuestionButton: () => import('Components/base/SkipQuestionButton')
  },

  data () {
    return {
      feedback: '',
      locked: false,
      form: {
        type: `NPS`,
        identifier: this.data.fields.title,
        question: this.data.fields.questionTitle,
        questionLength: this.data.fields.title.length,
        numberOfOptions: 11,
        id: this.data.sys.id,
        from: 0,
        to: 10,
        selected: [],
        timeToCompletion: null,
        timestamps: {
          start: new Date(),
          finish: null,
          valid: null
        }
      },
      timer: null
    }
  },

  methods: {
    retry () {
      this.form.selected = []
      this.locked = false
      this.feedback = ''
    },
    emitNext () {
      this.$emit('next', this.form)
    },
    selectThis (item) {
      if (this.locked) {
        return
      }
      this.form.selected = [item]
      this.form.timestamps.valid = new Date()
      this.form.timeToCompletion = this.form.timestamps.valid.getTime() - this.form.timestamps.start.getTime()
      setTimeout(() => {
        this.next()
      }, 50)
    },
    next () {
      this.form.timestamps.finish = new Date()
      this.calculateScores()
    },
    validInput (value) {
      // Add simple validation: if it exists, it is valid.
      if (value >= this.form.from && value <= this.form.to) {
        return true
      } else {
        return false
      }
    },
    calculateScores () {
      this.locked = true
      this.$store.dispatch('CALCULATE_WEIGHTED_QUESTION', {
        formula: this.data.fields.formula,
        selected: this.form.selected,
        options: this.scale,
        maxAnswers: 1,
        skip: this.data.fields.skip
      }).then(scores => {
        this.form.weighted = scores.weighted.slice(0)
        this.form.weightedMin = scores.weightedMin
        this.form.weightedMax = scores.weightedMax
        this.feedback = scores.feedback
        this.form.skip = scores.newSkip
        if (this.data.fields.randomSkip) {
          var items = this.data.fields.randomSkip.split(',')
          this.form.skip = items[items.length * Math.random() | 0]
        }
        this.emitNext()
      }).catch(err => {
        console.log('err: ', err)
      })
    }
  },

  computed: {
    scale () {
      if (this.data.fields.useOneToTen) {
        return [1, 2, 3, 4, 5, 6, 7, 8, 9, 10]
      }
      return [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10]
    },
    chromaList () {
      return chroma.scale(['#f56c6c', '#88d8b0']).mode('lch').colors(this.data.fields.steps + 1)
    }
  },

  watch: {
    // Add a simple watch to trigger next automatically after some ms
    'form.selected': function (newVal, oldVal) {
      if (this.validInput(newVal) && Number.isInteger(newVal)) {
        setTimeout(() => {
          this.next()
        }, 50)
      }
    }
  }
}
</script>

<style lang="scss">
</style>

<style scoped lang="scss">
.content {
  flex-direction: column;
}

.nps-wrapper,
.slider-labels {
  width: 90%;
  margin: 0 auto;
  margin-bottom: 20px;
  display: flex;
  flex-orientation: row;
  justify-content: space-between;
  align-items: center;

  @include breakpoint($tablet) {
    width: 45vh;
  }

  @include breakpoint($desktop) {
    width: 360px;
  }
  @include breakpoint($air) {
    width: 390px;
  }
  @include breakpoint($fullhd) {
    width: 460px;
  }

  .nps-box {
    width: 40px;
    height: 40px;
    line-height: 40px;
    background: white;
    border: 1px solid rgba($gray-medium, 0.6);
    border-right: none;
    font-size: 1.2em;

    &:last-child {
      border-right: 1px solid rgba($gray-medium, 0.6);
    }

    @include breakpoint($tablet) {
      width: 150px;
      height: 50px;
      line-height: 50px;
    }
    &.selected {
      border-right: 1px solid rgba($gray-medium, 0.6);
      background-color: $color-emphasis-alt;
      animation: standOut 0.4s ease-in-out forwards;
    }
  }
}

.slider-labels {
  font-weight: normal;
  font-size: 16px;
  div {
    @include breakpoint($desktop) {
      font-size: 0.7em;
    }
  }
  .label-item {
    font-size: 18px;
    max-width: 37vw;
    text-align: left;
    &:last-child {
      text-align: right;
    }
    width: min-intrinsic;
    width: -webkit-min-content;
    width: -moz-min-content;
    width: min-content;
    display: table-caption;
    display: -ms-grid;
    -ms-grid-columns: min-content;
  }

}
</style>
